import React from 'react'
import { Container, Flex, Box, css, Text, Card } from 'theme-ui'
import ListItem from '@solid-ui-components/ListItem'
import Icon from '@solid-ui-components/ContentIcon'
import ContentText from '@solid-ui-components/ContentText'
import Reveal from '@solid-ui-components/Reveal'
import Divider from '@solid-ui-components/Divider'
import ContentImages from '@solid-ui-components/Educabot/ContentImages'
import ContentButtons from '@solid-ui-components/ContentButtons/ContentButtons'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'

const styles = {
  items: {
    display: 'none',
      '@media (max-width: 765px)': {
        display: 'block',
        paddingLeft: '19px'
      },
  },
  title: {
    fontSize: 30,
    fontWeight: 600,
    color: '#2A205E',
    '@media (max-width: 765px)': {
      fontSize: 30,
    },
    '@media (max-width: 450px)': {
      lineHeight: '40px',
      marginBottom: '1rem'
    },
  },
  contentTitle : { 
    display: 'flex', 
    alignItems: 'center',
    justifyContent: 'flex-start',
    '@media (max-width: 991px)': {
      justifyContent: 'center',
      alignItems: 'baseline',
    },
    '@media (max-width: 765px)': {
      justifyContent: 'flex-start',
    },
  },
  contentText: {
    paddingLeft: '30px'
  },
  cardContainer: {
    paddingLeft: '25px',
    paddingBottom: '10px'
  },
  card: {
    border: '1px solid#01CEAA',
    color: '#01CEAA',
    height: '35px',
    paddingTop: '9px',
    borderRadius: '9px',
    padding: '3px 15px 3px 15px',
    width: 'max-content',
    '@media (max-width: 991px)': {
      margin: 'auto'
    },
    '@media (max-width: 749px)': {
      margin: 'initial'
    },
    '@media (max-width: 743px)': {
      padding: '5px 15px 3px 15px',
    }
  }
}

const HeroBlock01 = ({
  content: { text = [], images, buttons, collection, icon },
  reverse
}) => (
  <Container>
    <Flex
      sx={{
        alignItems: [null, null, null, `center`],
        flexDirection: [
          reverse ? 'column' : `column-reverse`,
          null,
          null,
          reverse ? `row-reverse` : `row`
        ]
      }}
    >
      <Box
        sx={{
          flexBasis: [null, null, null, `3/5`],
          [reverse ? 'ml' : 'mr']: [null, null, null, 5],
          position: `relative`,
          textAlign: `center`
        }}
      >
        <ContentImages
          content={{ images }}
          loading='eager'
          reverse={reverse}
          imagePosition='center'
        />
      </Box>

      <Box
        sx={{
          flexBasis: `2/5`,
          textAlign: [`left`, 'left', 'center', `left`]
        }}
      >
        <Reveal effect='fadeInDown'>
          <Box sx={styles.cardContainer}>
            <Card m='2' bg="#E6FAF7" sx={styles.card}>
              Smart Team
            </Card>
          </Box>
        <Box sx={styles.contentTitle}>
          <Icon content={icon} mr='2' size='10px' p='2' round />
            <Text sx={styles.title}>
              Sumá <span style={{ color: '#01CEAA'}}>robótica educativa</span>
            </Text>
        </Box>
        <Box sx={styles.contentText}>
          <ContentText content={text} />
        </Box>
        </Reveal>
        {collection && (
          <>
          <Box sx={styles.items}>
            <Divider space={3} />
            <Reveal
              effect={reverse ? 'fadeInRight' : 'fadeInLeft'}
              duration={1.5}
              >
              {collection.map((props, index) => (
                <>
                  <ListItem key={`item-${index}`} {...props} />
                  <Divider space={2} />
                </>
              ))}
            </Reveal>
          </Box>
          </>
        )}
        {buttons && (
          <Reveal
            effect='fadeInRight'
            delay={1}
            css={css({ mb: [4, null, null, 0], paddingLeft: '30px' })}
          >
            {buttons && (
              <>
                <ContentButtons content={buttons} />
              </>
            )}
          </Reveal>
        )}
      </Box>
    </Flex>
  </Container>
)


export default WithDefaultContent(HeroBlock01)
