import React from 'react'
import { graphql } from 'gatsby'
import { Container, Box } from 'theme-ui'
import Helmet from 'react-helmet'
import Tabs from '@solid-ui-components/Educabot/Tabs'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import ModalVideo from '@solid-ui-blocks/ModalVideo/Block01'
import Header from '@solid-ui-blocks/Educabot/Header/header'
import Hero from '@solid-ui-blocks/Educabot/Hero/Block01'
import HeroThree from '@solid-ui-blocks/Educabot/Hero/Block03'
import HeroFour from '@solid-ui-blocks/Educabot/Hero/Block04'
import HeroFive from '@solid-ui-blocks/Educabot/Hero/Block05'
import HeroSix from '@solid-ui-blocks/Educabot/Hero/Block08'
import HeroTrustUs from '@solid-ui-blocks/Educabot/Hero/Block07'
import Contact from '@solid-ui-blocks/Educabot/Hero/Block06'
import Testimonials from '@solid-ui-blocks/Educabot/Testimonials/Block03'
import FeatureOne from '@solid-ui-blocks/Educabot/Features/Block01'
import FeatureTwo from '@solid-ui-blocks/Educabot/Features/Block01'
import FeatureThree from '@solid-ui-blocks/Educabot/Features/Block01'
import FeatureCodit from '@solid-ui-blocks/Educabot/Features/Block01'
import Footer from '@solid-ui-blocks/Educabot/Footer/Block01'
import Posts from '@solid-ui-blocks/Educabot/Blog/Block01'

import WithRecentPosts from '@solid-ui-blocks/WithRecentPosts'

import { normalizeBlockContentNodes } from '@blocks-helpers'

import favicon from '../../../../../site/content/assets/favicon.png'
import bg from '../../../../../site/content/assets/flexiblocks/homepage/wallpaper-head.svg'
import Whastapp from '../../../../solid-ui/solid-ui-components/src/Whastapp/Whastapp'

const styles = {
  header: {
    bg: '#27A8FF',
    pt: '1rem',
    backgroundImage: `url(${bg})`,
    backgroundRepeat: `no-repeat`,
    backgroundPosition: `bottom`,
    backgroundPositionY: '101%',
    '@media (max-width: 1300px)': {
      // pb: '2rem',
      mb: '-1px'
    }
  },
  contact: {
    bg: '#27A8FF'
  },
  heroThree: {
    bg: '#FFFFFF'
  },
  heroFour: {
    bg: '#FFFFFF',
    pt: '2rem'
  },
  heroFive: {
    bg: '#FFFFFF',
    pt: '6rem',
    pb: '3rem'
  },
  sections: {
    bg: '#FFFFFF',
    pt: '3rem'
  },
  features: {
    pb: '10rem',
    '@media (max-width: 991px)': {
      pb: '2rem',
    },
  },
  testimonials: {
    bg: '#FFFFFF',
    pt: '8rem'
  },
  trustUs: {
    bg: '#F7F8FC',
    pt: '5rem',
    pb: '5rem'
  },
  blog: {
    bg: '#FFFFFF',
    pt: '5rem',
    pb: '5rem'
  },
  heroSix: {
    bg: '#FFFFFF',
    pt: '6rem',
    pb: '200px'
  }
}

const HomePage = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout {...props}>
      <Seo title='EDUCABOT' />
      <Helmet>
        <link rel="icon" href={favicon} />
      </Helmet>
      <ModalWithTabs content={content['contact']} />
      <ModalVideo content={content['proxy']} />
        <Header content={content['header']} />
        <Box sx={styles.header}>
          <Hero content={content['hero']} reverse />
        </Box>
        <Box sx={styles.features}>
          <Container variant='wide' sx={styles.tabsContainer}>
            <Tabs sx={styles.blocks} id="tabs" >
                <FeatureCodit content={content['feature-codit']} />
                <FeatureOne content={content['feature-one']} />
                <FeatureTwo content={content['feature-two']} />
                <FeatureThree content={content['feature-three']} />
            </Tabs>
          </Container>
        </Box>
        <Box sx={styles.heroThree}>
          <HeroThree content={content['heroThree']} reverse />
        </Box>
        <Box sx={styles.heroFour}>
          <HeroFour content={content['heroFour']} />
        </Box>
        <Box sx={styles.heroFive}>
          <HeroFive content={content['heroFive']} reverse />
        </Box>
        <Box sx={styles.heroSix}>
          <HeroSix content={content['heroSix']} />
        </Box>
        <Box sx={styles.trustUs}>
          <HeroTrustUs content={content['feature-seven']} reverse />
        </Box>
        <Box sx={styles.blog}>
          <WithRecentPosts limit={4}>
              <Posts content={content['posts-educabot']} reverse />
          </WithRecentPosts>
        </Box>

        <Box sx={styles.testimonials}>
          <Testimonials content={content['testimonials']} />
        </Box>
        <Box sx={styles.sections}>
          <Footer content={content['footer']} />
        </Box>
    </Layout>
  )
}

export const query = graphql`
  query miscIndexBlockContent {
    allBlockContent(filter: { page: { in: ["homepage/educabot"] } }) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default HomePage
