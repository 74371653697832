import React, { useContext, useEffect, useState, useRef } from 'react'
import { Flex, Box, Text, Image, css } from 'theme-ui'
import { Helmet } from "react-helmet";
import Reveal from '@solid-ui-components/Reveal'
import Divider from '@solid-ui-components/Divider'
import Tabs from '@solid-ui-components/Educabot/TabsContact/Tabs'
import Modal from '@solid-ui-components/Modal'
import ListItem from '@solid-ui-components/ListItem'
import { TabsContext } from '@solid-ui-components/Tabs'
import ContentText from '@solid-ui-components/ContentText'
import ContentImages from '@solid-ui-components/ContentImages'
import ContentMap from '@solid-ui-components/ContentMap'
import { ModalContext } from '@solid-ui-components/Modal'
import { FormContact } from '../../../../solid-ui-components/src/FormContact/FormContact';
import ContentButtons from '@solid-ui-components/ContentButtons/ContentButtons'

import movie from '../../../../../../site/content/assets/flexiblocks/homepage/movie.png'

const styles = {
  title: {
    fontSize: '45px',
    fontWeight: 600,
    color: '#09005D',
    '@media (max-width: 765px)': {
      fontSize: '35px',
    },
  },
  subtitle: {
    fontWeight: 600,
    color: '#09005D',
    textAlign: 'center'
  },
  modalContact: {
    display: `flex`,
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flex: 1,
    minHeight: '550px',
    position: `relative`,
    overflow: `hidden`,
    bg: '#FFFFFF'
  }
}

const ModalBlock01 = ({ content, reverse }) => {
  const { activeModal } = useContext(ModalContext)
  const {
    activeTab: { index = 0 }
  } = useContext(TabsContext)

  const { identifier, text, buttons } = content

  if (activeModal && activeModal !== identifier) return null

  return (
    <Modal
        id={identifier}
        contentStyles={{
          p: 0,
          width: 800,
          '@media (max-width: 991px)': {
            width: '90%'
          },
        }}
      >
      <Box sx={styles.modalContact}>
        <Text sx={styles.title}>¡Próximamente!</Text>
        <Image src={movie} />
        <Text sx={styles.subtitle}>Mientras tanto conocé más sobre <span style={{ color: '#F45C3A'}}>Suite de aplicaciones</span></Text>
        <ContentButtons content={buttons} />
      </Box>
    </Modal>
  )
}

export default ModalBlock01
