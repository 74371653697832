import React from 'react'
import { Container, Flex, Box, css, Text } from 'theme-ui'
import Icon from '@solid-ui-components/ContentIcon'
import ContentText from '@solid-ui-components/ContentText'
import Reveal from '@solid-ui-components/Reveal'
import Divider from '@solid-ui-components/Divider'
import ContentImages from '@solid-ui-components/Educabot/ContentImages'
import QuickSignupForm from '@solid-ui-components/QuickSignupForm'
import ContentButtons from '@solid-ui-components/ContentButtons/ContentButtons'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'
import ListItem from '@solid-ui-components/ListItem'

const styles = {
  items: {
    display: 'none',
      '@media (max-width: 860px)': {
        display: 'block'
      },
  },
  title: {
    fontSize: 30,
    fontWeight: 600,
    color: '#2A205E'
  },
  text: {
    '@media (max-width: 1014px)': {
      marginTop: '2rem'
    }
  }
}

const HeroBlock01 = ({
  content: { text = [], images, buttons, form, icon, collection },
  reverse
}) => (
  <Container>
    <Flex
      sx={{
        alignItems: [null, null, null, `center`],
        flexDirection: [
          reverse ? 'column-reverse' : `column`,
          null,
          null,
          reverse ? `row-reverse` : `row`
        ]
      }}
    >
      <Box
        sx={{
          flexBasis: [null, null, null, `3/5`],
          [reverse ? 'ml' : 'mr']: [null, null, null, 5],
          position: `relative`,
          textAlign: `center`,
          marginTop: '-1rem'
        }}
      >
        <Box sx={styles.contentImages}>
          <ContentImages
            content={{ images }}
            loading='eager'
            reverse={reverse}
            imagePosition='center'
          />
        </Box>
      </Box>
      <Box
        sx={{
          flexBasis: `2/5`,
          textAlign: ['center', 'center', 'center', `left`]
        }}
      >
        <Box sx={styles.text}>
          <Reveal effect='fadeInDown'>
            <Icon content={icon} mr='3' size='10px' p='2' round />
            <ContentText content={text} />
          </Reveal>
        </Box>
        {collection && (
          <>
          <Box sx={styles.items}>
            <Reveal
              effect={reverse ? 'fadeInRight' : 'fadeInLeft'}
              duration={1.5}
              >
              {collection.map((props, index) => (
                <>
                  <ListItem key={`item-${index}`} {...props} />
                  <Divider space={2} />
                </>
              ))}
            </Reveal>
          </Box>
          </>
        )}
        {buttons && (
          <Reveal
            effect='fadeInRight'
            delay={1}
            css={css({ 
              mb: [4, null, null, 0], 
              marginLeft: '-2rem'
            })}
          >
            {buttons && (
              <>
                <Divider space={2} />
                <ContentButtons content={buttons} />
              </>
            )}
          </Reveal>
        )}
        {form && (
          <Reveal
            effect='fadeInRight'
            delay={1}
            css={css({ mb: [4, null, null, 0] })}
          >
            <QuickSignupForm {...form} space={3} />
          </Reveal>
        )}
      </Box>
    </Flex>
  </Container>
)


export default WithDefaultContent(HeroBlock01)
